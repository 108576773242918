import React from 'react';

import { Link } from 'gatsby';

import { useLocation } from "@reach/router"

const SidebarJobs = () => {

    const position = useLocation()

    let title;

    if(position.pathname !== "/recruit/jobs/"){
        title = "その他の募集職種"
    }else{
        title = "募集職種"
    }



    return(
        <>

            <article className="p-recruit-jobs">

                <section className="p-recruit-jobs-section">

                    <div className="p-recruit-section-box is-detail">
                        <div className="p-recruit-job">
                            <p className="p-recruit-job--text">
                            {title}
                            </p>
                        </div>
                    </div>

                    <div className="p-recruit-section-box">
                        <div className="p-recruit-job">
                            <p className="p-recruit-job--text">
                            エンジニア・クリエイティブ
                            </p>
                        </div>

                        <div className="p-recruit-cat">
                            <p className="p-recruit-cat--text">
                            正社員
                            </p>
                        </div>

                        <div className="p-recruit-list">

                            <div className="p-recruit-list-item">
                                <div className="p-recruit-list-item-job">
                                    <Link to="/recruit/detail/backend" title="バックエンドエンジニア" className="p-recruit-list-item-job--text">
                                    バックエンドエンジニア
                                    </Link>
                                </div>

                                <div className="p-recruit-list-item-area">
                                    <p className="p-recruit-list-item-area--text">
                                    金沢本社、東京事務所、大阪事務所
                                    </p>
                                </div>

                                <div className="p-recruit-list-item-desc">
                                    <p className="p-recruit-list-item-desc--text">
                                    MOSや弊社開発システムの開発案件をご担当していただきます。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <Link to="/recruit/detail/backend" title="詳しく見る" className="c-link--btn">
                                    詳しく見る<span className="c-link--cursor"></span>
                                    </Link>
                                </div>
                            </div>

                            <div className="p-recruit-list-item">
                                <div className="p-recruit-list-item-job">
                                    <Link to="/recruit/detail/frontends" title="フロントエンドエンジニア" className="p-recruit-list-item-job--text">
                                    フロントエンドエンジニア
                                    </Link>
                                </div>

                                <div className="p-recruit-list-item-area">
                                    <p className="p-recruit-list-item-area--text">
                                    金沢本社
                                    </p>
                                </div>

                                <div className="p-recruit-list-item-desc">
                                    <p className="p-recruit-list-item-desc--text">
                                    MOSや弊社開発システムの画面設計や開発案件をご担当していただきます。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <Link to="/recruit/detail/frontends" title="詳しく見る" className="c-link--btn">
                                    詳しく見る<span className="c-link--cursor"></span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="p-recruit-section-box">
                        <div className="p-recruit-job">
                            <p className="p-recruit-job--text">
                            セールス
                            </p>
                        </div>

                        <div className="p-recruit-cat">
                            <p className="p-recruit-cat--text">
                            正社員
                            </p>
                        </div>

                        <div className="p-recruit-list">

                            <div className="p-recruit-list-item">
                                <div className="p-recruit-list-item-job">
                                    <Link to="/recruit/detail/sales" title="ソリューションプランナー" className="p-recruit-list-item-job--text">
                                    ソリューションプランナー
                                    </Link>
                                </div>

                                <div className="p-recruit-list-item-area">
                                    <p className="p-recruit-list-item-area--text">
                                    東京事務所、大阪事務所
                                    </p>
                                </div>

                                <div className="p-recruit-list-item-desc">
                                    <p className="p-recruit-list-item-desc--text">
                                    Webや展示会等を訪れたお客さまとの商談と通じてお客様の課題に寄り添い、MOSの導入・運用をサポートします。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <Link to="/recruit/detail/sales" title="詳しく見る" className="c-link--btn">
                                    詳しく見る<span className="c-link--cursor"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </article>

        </>
    )
}

export default SidebarJobs;


