import React, { useState, useEffect } from 'react';

import { withPrefix } from "gatsby"

import { StaticImage } from "gatsby-plugin-image";

import { Link } from 'gatsby';

import ReactVivus from 'react-vivus';

import InlineLogo from '../svg/inline.svg';

const SidebarRecruit = (props) => {



    const outlineSvg = withPrefix('/') + 'outline.svg'



    const [animShow, setAnimShow] = useState(true);
    const [animStat, setAnim] = useState(false);

    useEffect(() => {

        if(animStat === true){
            document.getElementsByClassName('js-logo-outline')[0].classList.add('is-hide')
            document.getElementsByClassName('js-logo-anim')[0].classList.remove('is-hide')
        }

        return () => {
            setAnimShow(false)
            setAnim(false)
        };
    },[animStat]);

    const loadLogo = () =>{
        setAnim(true)
    }



    return(
        <>

            <div className="p-recruit-wrap">
                <div className="p-recruit-nav">
                    <nav className="p-recruit-nav-list">

                        <Link to="/recruit/jobs/" title="" className="p-recruit-nav-btn is-job" activeClassName="is-active">
                            <div className="p-recruit-nav-btn-cnt">
                                <p className="p-recruit-nav-btn-cnt--title">
                                募集職種
                                </p>

                                <p className="p-recruit-nav-btn-cnt--text">
                                あなたの発想で<br />
                                しくみを創る
                                </p>
                            </div>

                            <StaticImage
                                src="../images/bg/p_recruit_nav_job.png"
                                alt="募集職種"
                                placeholder="blurred"
                                quality="100"
                                className="p-recruit-nav-btn-image"
                            />
                        </Link>

                        <Link to="/recruit/culture" title="" className="p-recruit-nav-btn is-welfare" activeClassName="is-active">
                            <div className="p-recruit-nav-btn-cnt">
                                <p className="p-recruit-nav-btn-cnt--title">
                                成長支援
                                </p>

                                <p className="p-recruit-nav-btn-cnt--text">
                                フレキシブルに<br />
                                仕事を楽しむ
                                </p>
                            </div>

                            <StaticImage
                                src="../images/bg/p_recruit_nav_welfare.png"
                                alt="成長支援"
                                placeholder="blurred"
                                quality="100"
                                className="p-recruit-nav-btn-image"
                            />
                        </Link>

                        <Link to="/solution" title="" className="p-recruit-nav-btn is-business is-single">
                            <div className="p-recruit-nav-btn-cnt">
                                <div className="p-recruit-nav-btn-cnt-inner u-align-center">
                                    <p className="p-recruit-nav-btn-cnt--title">
                                    事業内容
                                    </p>

                                    <p className="p-recruit-nav-btn-cnt--text">
                                    「受発注業務を変える。」を根幹に<br />様々な事業を展開
                                    </p>
                                </div>
                            </div>

                            <StaticImage
                                src="../images/bg/p_recruit_nav_business.png"
                                alt="事業内容"
                                placeholder="blurred"
                                quality="100"
                                className="p-recruit-nav-btn-image"
                            />
                        </Link>

                    </nav>
                </div>



                <div className="p-recruit-hero">
                    <div className="p-recruit-hero-inner">

                        <Link to="/" title="" className="p-recruit-hero-box">

                            <div className="p-recruit-hero-box-inner">
                                {/* <p className="p-recruit-hero-box--text u-align-center">
                                    アクロスソリューションズを知る
                                </p> */}

                                <div className="p-recruit-hero-logo-wrap">
                                    {animShow ? <ReactVivus
                                        id="logo"
                                        className="p-recruit-hero-logo is-outline js-logo-outline"
                                        option={{
                                        file:outlineSvg,
                                        duration:100,
                                        animTimingFunction: "EASE",
                                        type: "oneByOne",
                                        }}
                                        callback={
                                            loadLogo
                                        }
                                    />
                                    : null}

                                    <InlineLogo className="p-recruit-hero-logo is-hide js-logo-anim" />
                                </div>
                            </div>

                        </Link>

                    </div>
                </div>
            </div>

        </>
    )
}

export default SidebarRecruit;


