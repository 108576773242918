import React from 'react';

import Meta from '../../components/meta';

import Contents from '../../components/contents';

import SidebarRecruit from '../../components/sidebarRecruit';

import SidebarJobs from '../../components/sidebarJobs';

const Jobs = () =>{



    return(
        <>

            <Meta
                title="募集職種"
                description="あなたの発想でしくみを創る"
            />

            <Contents>

                <div className="c-sub-bg is-job">

                    <div className="c-sub-header">
                        <em className="c-sub-header--eng is-sp u-josef">
                        RECRUIT<br />
                        JOB
                        </em>

                        <h1 className="c-sub-header--title">
                        あなたの発想でしくみを創る
                        </h1>
                    </div>

                </div>



                <SidebarJobs / >



                <SidebarRecruit />



            </Contents>

        </>
    )
}

export default Jobs;


